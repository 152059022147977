import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useTheme } from '@jl/assets';
import { mapboxSettings, AddressValue, isVisibleAddress } from '@jl/utils';

import { Icon } from './icon';

export type AddressMapProps = {
  setValue: (v: AddressValue) => void;
  value: AddressValue | undefined;
};

const AddressMap = ({ value }: AddressMapProps) => {
  const theme = useTheme();
  const display = isVisibleAddress(value) ? value : undefined;
  return (
    <ReactMapGL
      mapboxAccessToken={mapboxSettings.accessToken}
      mapStyle={theme.map}
      style={{ width: 200, height: 100 }}
      zoom={14}
      {...display}
    >
      {display && (
        <Marker {...display} offset={[-15, -30]}>
          <Icon name="marker" colour={theme.success} size={30} />
        </Marker>
      )}
    </ReactMapGL>
  );
};

export default AddressMap;
